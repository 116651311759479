import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import LayoutDefault from "../layouts/default"
import AboutHero from "../components/about-hero"
import TextColumns from "../components/text-columns"

const AboutPage = ({ data }) => {

  console.log(data)

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        <AboutHero input={ data } />
        <TextColumns input={ data } />
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default props => (
  <StaticQuery
    query={ graphql`
      query AboutQuery {
        prismic {
          about(lang: "de-de", uid: "about") {
            page_title
            intro_text
            info_section {
              body_text
              headline
            }
          }
        }
      }
    ` }
    render={ data => <AboutPage data={ data } { ...props } /> }
  />
)