import styled from "styled-components"
import * as themeSettings from "../../styles/theme"

export const Hero = styled.section`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  /* height: 100vh; */
  height: auto;
  margin-top: calc(${themeSettings.elementHeights.navDesktop}px * 2);
  padding: ${themeSettings.margins.page};

  line-height: calc(${themeSettings.lineHeights.large} * 1.04);
  font-size: calc(${themeSettings.fontSizes.large} * 0.8);

  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 1440px) {
    font-size: calc(${themeSettings.fontSizes.large} * 0.5);
  }

  @media screen and (max-width: 720px) {
    margin-top: ${themeSettings.elementHeights.navDesktop}px;
    font-size: calc(${themeSettings.fontSizes.base} * 1.4);
    line-height: ${themeSettings.lineHeights.mobileLarge};
  }

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.margins.large} ${themeSettings.mobileMargins.page};
  }

  @media screen and (max-width: 375px) {
    font-size: calc(${themeSettings.fontSizes.base} * 1.2);
  }
`