import styled, { keyframes } from "styled-components"
import * as themeSettings from "../../styles/theme"

const wobble = keyframes`
  0% { transform: skew(0) }
  20% { transform: skew(-10deg) }
  40% { transform: skew(10deg) }
  60% { transform: skew(-5deg) }
  80% { transform: skew(5deg) }
  90% { transform: skew(2deg) }
  100% { transform: skew(0) }
`

export const Section = styled.section`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  padding: ${themeSettings.margins.large} calc(${themeSettings.margins.page} * 4);

  @media screen and (max-width: 968px) {
    padding: ${themeSettings.margins.large} ${themeSettings.margins.page};
    min-height: auto;
    height: auto;
  }

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.margins.large} ${themeSettings.mobileMargins.page};
  }
`

export const Columns = styled.ul`
  columns: 2;
  column-gap: calc(${themeSettings.margins.page} * 4);

  @media screen and (max-width: 968px) {
    columns: 1;
    column-gap: 0;
  }
`

export const ColumnItem = styled.li`
  break-inside: avoid-column;
  margin-bottom: calc(${themeSettings.margins.page} * 1.6);
  list-style-type: none;
  & h3 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }
  & span {
    & p {
      a {
        white-space: nowrap;
        display: inline-block;
        &:hover {
          animation: 0.8s ${wobble} ease-out;
        }
      }
    }
  }
`

export const LinkContainer = styled.div`
  width: 100%;
  margin-top: ${themeSettings.margins.large};
  display: flex;
  align-items: flex-start;
  justify-content: center;
`