import React from "react"
import * as Styled from "./styled"

const AboutHero = props => {

  return (
    <Styled.Hero>
      <h2>
        { props.input.prismic.about.intro_text[0].text }
      </h2>
    </Styled.Hero>
  )
}

export default AboutHero