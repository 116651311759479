import React from "react"
import { RichText } from "prismic-reactjs"
import AnimatedLink from "../animated-link"
import * as Styled from "./styled"

const TextColumns = props => {
  const items = props.input.prismic.about.info_section.map((section, index) => {
    return (
      <Styled.ColumnItem>
        <h3>{section.headline[0].text}</h3>
        <span>{RichText.render(section.body_text)}</span>
      </Styled.ColumnItem>
    )
  })

  return (
    <Styled.Section>
      <Styled.Columns>{items}</Styled.Columns>
      <Styled.LinkContainer>
        <AnimatedLink
          isInternalLink={false}
          target="mailto:invoice@studiofabiobiesel.com"
          linkText="Go!"
        />
      </Styled.LinkContainer>
    </Styled.Section>
  )
}

export default TextColumns
